.mc {

    .frm-test {
        color: #fff;
    }

    .frm_ {

        &opt_container {
            @extend .is-clearfix;
            margin-top: 1rem;
        }

        &button_submit {
            @extend .button;
            @extend .is-link;
            @extend .is-medium;
            font-weight: 800;
        }

        &error {
            @extend .notification;
            @extend .is-danger;
            margin-top: 0.5rem;
            background: #EB4511;
            font-size: 1rem;
            padding: 0.6rem 0.8rem;
        }
        
        &message {
            @extend .notification;
            @extend .is-primary;
            margin-bottom: 1rem;
            background: #03CEA4;
        }

        &form_field {
            margin-bottom: 24px;
        }
        
        &prev_page {
            display: none;
        }

        &scale {
            float: left;
            margin-right: 15px;
            text-align: center;
        }

        &primary_label {
            font-weight: 700;
        }
        
    }

}