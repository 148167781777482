.mc {
    .content {
        ul, ol {
            margin-left: 1.2rem;
        }
        a {
            font-weight: 700;
            border-bottom: solid 2px;
        }
        h2.is-main-subtitle {
            color: lighten($dark-grey,8%);
        }
    }
}

.is-featured-content {
    .mc & {
        margin: 0;
        @include tablet {
            margin: 1rem;
        }
    }
}